.header_title {
  background-color: #404e73;
  height: 64px;
  justify-content: center;
  padding: 15px;
}

/* display: flex;
  font-family: "Roboto", sans-serif;
  background-color: #5b5981;
  color: white;
  height: 64px;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  border-bottom: 2px solid #001d3d;
  font-weight: bold;
  font-size: large; */

.header_body {
  padding: 0;
  background: #404e73;
  border-bottom: 2px solid #001d3d;
}
.headericons {
  padding-right: 5px;
}

.nav {
  margin-bottom: 25px;
}

.nav a {
  font-family: "Roboto", sans-serif;
  color: #fff;
}

.nav a.active {
  padding-left: 0px;
  font-weight: bold;
}
.nav a.inactive {
  padding-left: 0px;
  color: #d8e2dc;
}

.sider {
  background: #404e73;
}

.content {
  background-color: #f3f4f6;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.logout {
  text-align: right;
  padding-right: 20px;
  color: white;
  font-weight: bold;
  font-size: small;
}
