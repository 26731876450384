.usersboxestop {
  padding-top: 10px;
  background-color: white;
  min-height: 1vh;
  padding-right: 10px;
}

.usersboxesbottom {
  padding-top: 30px;
  background-color: white;
  padding-bottom: 30px;
  min-height: 79vh;
}

.adduserbutton {
  background-color: #5b5981;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}

.useraddformtop {
 background-color: white; 
 min-height: 77vh;
}

.useraddformheader {
  background-color: white; 
}