.fileuploadboxestop {
  padding-top: 30px;
  background-color: white;
  padding-bottom: 30px;
  min-height: 40vh;
}

.fileuploadboxesbottom {
  padding-top: 30px;
  background-color: white;
  padding-bottom: 30px;
  min-height: 40vh;
}

.fileuploadinfo {
  background-color: white;
  padding: 30px;
}

.fileuploadwarning {
  background-color: white;
  padding: 30px;
    min-height: 53vh;
}
