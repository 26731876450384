.livecountingboxestop {
  padding-top: 10px;
  background-color: white;
  min-height: 1vh;
  padding-right: 10px;
}

.livecountingboxesbottom {
  padding-top: 30px;
  background-color: white;
  padding-bottom: 30px;
  min-height: 79vh;
}

.refresh {
  background-color: #5b5981;
  text-align: center;
  padding: 5px;
  border-radius: 25px;
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 12pt;
}
