@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Roboto:wght@300&display=swap");

.main {
  min-height: 100vh;
  background-color: #404e73;
}

.loginbutton {
  background: #5b5981;
  color: white;
  text-align: center;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #1b263b;
}

.topbox {
  height: 100px;
}

.outerbox {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-family: "Nunito", sans-serif;
  height: 320px;
  border-radius: 10px;
  font-family: 'Nunito', sans-serif;
  font-size: 20pt;
}

