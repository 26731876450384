.claimdetailsheader {
 background-color: white; 
}

.claimdetailstop {
 background-color: white; 
}

.claimdetailsbottom {
 background-color: white; 
 min-height: 54vh;
}