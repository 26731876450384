.transactiondetailsheader {
 background-color: white; 
}

.transactiondetailstop {
 background-color: white; 
}

.transactiondetailsbottom {
 background-color: white; 
 min-height: 54vh;
}