.settingsheader {
  background-color: white;
  padding-top: 20px;
}

.settingstop {
  background-color: white;
  display: flex;
  justify-content: left;
  align-items: top;
  padding: 20px;
}

.settingstext {
  background-color: white;
  display: flex;
  justify-content: left;
  align-items: top;
  padding: 20px;
  font-size: 12pt;
  color: #5b5981;
  font-weight: bold;
}

.settingsbottom {
  background-color: white;
  min-height: 56vh;
}
