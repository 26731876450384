.hometitle {
  padding-top: 0px;
  background-color: white;
  padding-bottom: 30px;
}

.hometop {
  padding-top: 0px;
  background-color: white;
  padding-bottom: 30px;
}

.homebottom {
  padding-top: 60px;
  background-color: white;
  min-height: 42vh;
}

.homerefreshrate {
  padding-top: 0px;
  background-color: white;
}