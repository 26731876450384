.fileuploadboxestop {
  padding-top: 30px;
  background-color: white;
  min-height: 4vh
}

.fileuploadboxesbottom {
  background-color: white;
  padding-bottom: 30px;
  min-height: 65vh
}

